/*eslint-disable */

import { bus } from '@/main';

var mediaRecorder;
var recordedBlobs;

export default {
  name: 'video-recording-data',
  data() {
    return {
      recordButton: 'Start Recording',
      errorMsgElement: '',
      downloadRecordVideo: false,
      storeStreamData: false,
      vidTrack: {},
      recordingDuration: 0,
      timerInterval: null
    }
  },
  computed: {
    formattedDuration() {
      const minutes = Math.floor(this.recordingDuration / 60);
      const seconds = this.recordingDuration % 60;
      return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }
  },
  mounted() {
    this.videoInitMethod();
  },
  methods: {
    videoInitMethod() {
      const constraints = {
        audio: {
          echoCancellation: { ideal: true }
        },
        video: {
          width: { ideal: 854 },
          height: { ideal: 480 }
        }
      };
      this.init(constraints);
    },
    startRecording() {
      recordedBlobs = [];
      const options = { mimeType: 'video/webm;codecs=vp9,opus' };
      
      if (!MediaRecorder.isTypeSupported(options.mimeType)) {
        console.log(`${options.mimeType} is not supported`);
        options.mimeType = 'video/webm;codecs=vp8,opus';
        if (!MediaRecorder.isTypeSupported(options.mimeType)) {
          console.log(`${options.mimeType} is not supported`);
          options.mimeType = 'video/webm';
          if (!MediaRecorder.isTypeSupported(options.mimeType)) {
            console.log(`${options.mimeType} is not supported`);
            options.mimeType = '';
          }
        }
      }

      try {
        mediaRecorder = new MediaRecorder(window.stream, options);
      } catch (e) {
        console.error('Exception while creating MediaRecorder:', e);
        this.errorMsgElement = `Exception while creating MediaRecorder: ${JSON.stringify(e)}`;
        return;
      }

      this.recordButton = 'Stop Recording';
      this.downloadRecordVideo = false;
      this.recordingDuration = 0;
      this.startTimer();
      
      mediaRecorder.onstop = (event) => {
        console.log('Recorder stopped: ', event);
        this.stopTimer();
      };
      mediaRecorder.ondataavailable = this.handleDataAvailable;
      mediaRecorder.start(10); // Collect 10ms of data
    },
    stopRecording() {
      mediaRecorder.stop();
      this.downloadRecordVideo = true;
      this.stopTimer();
    },
    startTimer() {
      this.timerInterval = setInterval(() => {
        this.recordingDuration++;
      }, 1000);
    },
    stopTimer() {
      clearInterval(this.timerInterval);
    },
    uploadVideoOnDeviceMethod() {
      const blob = new Blob(recordedBlobs, { type: 'video/webm' });
      bus.$emit('videRecordDataBus', recordedBlobs);
      this.closeVideoRecordPopupMethod();
    },
    async handleSuccess(stream) {
      window.stream = stream;
      const gumVideo = this.$refs.gumVideo;
      gumVideo.srcObject = stream;
      this.storeStreamData = true;
    },
    async init(constraints) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        this.vidTrack = stream;
        this.handleSuccess(stream);
      } catch (e) {
        console.error('navigator.getUserMedia error:', e);
        this.errorMsgElement = `navigator.getUserMedia error: ${e.toString()}`;
      }
    },
    handleDataAvailable(event) {
      if (event.data && event.data.size > 0) {
        recordedBlobs.push(event.data);
      }
    },
    startRecordingButtonMethod() {
      if (this.recordButton === 'Start Recording') {
        this.startRecording();
      } else {
        this.stopRecording();
        this.recordButton = 'Start Recording';
      }
    },
    closeVideoRecordPopupMethod() {
      document.body.classList.remove('popup_open_body');
      bus.$emit('videRecordStateBus', false);
      
      if (window.stream) {
        window.stream.getTracks().forEach(track => track.stop());
      }
      
      if (this.$refs.gumVideo) {
        this.$refs.gumVideo.srcObject = null;
      }
      
      this.storeStreamData = false;
      this.vidTrack = {};
      window.stream = null;
      this.stopTimer();
      this.recordingDuration = 0;
    }
  }
}